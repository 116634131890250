<template>

  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-12">
        <indexHeader :title="$t('purchaseOrders.purchaseOrders')"
                     :description="$t('purchaseOrders.fromHereYouCanControlPurchaseOrders')" />
        <indexTable :searchText="$t('purchaseOrders.searchForAPurchaseOrder')"
                    :emptyTableText="$t('purchaseOrders.thereAreNoOrders')"
                    :emptyTableSubText="$t('allerts.TryAddingSomeItemsToTheTableAndTryAgain')"
                    :buttonRole="$user.role.purchase_orders_add"
                    :statusSearch="true"
                    :statusValues="[
                                   {title: $t('purchaseOrders.situation'), value: ''},
                                   {title: $t('purchaseOrders.all'), value: ''},
                                   {title: $t('purchaseOrders.draft'), value: 0},
                                   {title: $t('purchaseOrders.open'), value: 1},
                                   {title: $t('purchaseOrders.sent'), value: 2},
                                   {title: $t('purchaseOrders.invoiced'), value: 3},
                                   {title: $t('purchaseOrders.canceled'), value: 4},
                                   ]"
                    :cloumns="[
                                    {column: 'code',    title: $t('purchaseOrders.purchaseOrder') ,type: 'mainLink' , sort: true },
                                    {column: 'date',    title: $t('purchaseOrders.orderDate') ,type: 'text' , sort: true },
                                    {column: 'client_type',    title: $t('purchaseOrders.supplierName') ,type: 'clintLink', sort: true},
                                    {column: 'total',    title: $t('purchaseOrders.cost') ,type: 'text' , sort: true },
                                    {column: 'status',    title: $t('purchaseOrders.situation') ,type: 'status' , sort: true ,  values: [
                                      {title: $t('purchaseOrders.draft'), value: 0, color: 'dark'},
                                      {title: $t('purchaseOrders.open'), value: 1, color: 'primary'},
                                      {title: $t('purchaseOrders.sent'), value: 2, color: 'primary'},
                                      {title: $t('purchaseOrders.invoiced'), value: 3, color: 'success'},
                                      {title: $t('purchaseOrders.canceled'), value: 4, color: 'danger'},
                                    ]},
                                    {column: 'options', title: $t('purchaseOrders.settings'), type: 'options', options: [
                                        {name: 'show'},
                                        {name: 'edit',   role: $user.admin || $user.role.purchase_orders_edit},
                                        {name: 'file'},
                                        {name: 'outLink',   role: true},
                                        {name: 'printPurchaseOrder',   role: true},
                                        {name: 'download',   role: true},
                                        {name: 'delete', role: $user.admin || $user.role.purchase_orders_delete},
                                    ]}
                                  ]"
                    :deleteText="{
                                   attention: $t('allerts.Attention'),
                                   areYouReallySureToDeleteTheItem: $t('allerts.areYouReallySureToDeleteTheItem'),
                                   close: $t('allerts.close'),
                                   confirm: $t('allerts.confirm'),
                                   loading: $t('allerts.loading'),
                                  }" />
      </div>
    </div>
  </div>

</template>
<script>

  import indexHeader from '../elements/index/indexHeader.vue'
  import indexTable from '../elements/index/indexTable.vue'

  export default {
    data() {
      return {
        path: '/purchaseOrders',
      }
    },
    components: {
      indexHeader,
      indexTable,
    }
  }

</script>
