var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12"},[_c('indexHeader',{attrs:{"title":_vm.$t('purchaseOrders.purchaseOrders'),"description":_vm.$t('purchaseOrders.fromHereYouCanControlPurchaseOrders')}}),_c('indexTable',{attrs:{"searchText":_vm.$t('purchaseOrders.searchForAPurchaseOrder'),"emptyTableText":_vm.$t('purchaseOrders.thereAreNoOrders'),"emptyTableSubText":_vm.$t('allerts.TryAddingSomeItemsToTheTableAndTryAgain'),"buttonRole":_vm.$user.role.purchase_orders_add,"statusSearch":true,"statusValues":[
                                 {title: _vm.$t('purchaseOrders.situation'), value: ''},
                                 {title: _vm.$t('purchaseOrders.all'), value: ''},
                                 {title: _vm.$t('purchaseOrders.draft'), value: 0},
                                 {title: _vm.$t('purchaseOrders.open'), value: 1},
                                 {title: _vm.$t('purchaseOrders.sent'), value: 2},
                                 {title: _vm.$t('purchaseOrders.invoiced'), value: 3},
                                 {title: _vm.$t('purchaseOrders.canceled'), value: 4} ],"cloumns":[
                                  {column: 'code',    title: _vm.$t('purchaseOrders.purchaseOrder') ,type: 'mainLink' , sort: true },
                                  {column: 'date',    title: _vm.$t('purchaseOrders.orderDate') ,type: 'text' , sort: true },
                                  {column: 'client_type',    title: _vm.$t('purchaseOrders.supplierName') ,type: 'clintLink', sort: true},
                                  {column: 'total',    title: _vm.$t('purchaseOrders.cost') ,type: 'text' , sort: true },
                                  {column: 'status',    title: _vm.$t('purchaseOrders.situation') ,type: 'status' , sort: true ,  values: [
                                    {title: _vm.$t('purchaseOrders.draft'), value: 0, color: 'dark'},
                                    {title: _vm.$t('purchaseOrders.open'), value: 1, color: 'primary'},
                                    {title: _vm.$t('purchaseOrders.sent'), value: 2, color: 'primary'},
                                    {title: _vm.$t('purchaseOrders.invoiced'), value: 3, color: 'success'},
                                    {title: _vm.$t('purchaseOrders.canceled'), value: 4, color: 'danger'} ]},
                                  {column: 'options', title: _vm.$t('purchaseOrders.settings'), type: 'options', options: [
                                      {name: 'show'},
                                      {name: 'edit',   role: _vm.$user.admin || _vm.$user.role.purchase_orders_edit},
                                      {name: 'file'},
                                      {name: 'outLink',   role: true},
                                      {name: 'printPurchaseOrder',   role: true},
                                      {name: 'download',   role: true},
                                      {name: 'delete', role: _vm.$user.admin || _vm.$user.role.purchase_orders_delete} ]}
                                ],"deleteText":{
                                 attention: _vm.$t('allerts.Attention'),
                                 areYouReallySureToDeleteTheItem: _vm.$t('allerts.areYouReallySureToDeleteTheItem'),
                                 close: _vm.$t('allerts.close'),
                                 confirm: _vm.$t('allerts.confirm'),
                                 loading: _vm.$t('allerts.loading'),
                                }}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }